import { render, staticRenderFns } from "./CustomBranding.vue?vue&type=template&id=5e3de386&"
import script from "./CustomBranding.vue?vue&type=script&lang=js&"
export * from "./CustomBranding.vue?vue&type=script&lang=js&"
import style0 from "./CustomBranding.vue?vue&type=style&index=0&id=5e3de386&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QRouteTab,QDialog,QCard,QCardSection,QCardActions,QBtn,QFab,QFabAction,QTooltip});qInstall(component, 'directives', {ClosePopup});
