<template>
	<div
		v-if="
			current_CG.root_customer_group_permissions.includes(
				'Custom dashboard branding'
			) && is_ready
		"
	>
		<div class="tabs-wrapper">
			<q-tabs indicator-color="primary" align="justify" active-color="primary">
				<q-route-tab
					v-for="(tab, index) in tabs"
					:key="index"
					:to="{ name: tab.path_name }"
					:label="tab.label"
					:alert="hasError(tab.path_name)"
					alert-icon="priority_high"
				/>
			</q-tabs>
		</div>
		<div class="router-view-wrapper">
			<div>
				<router-view></router-view>
			</div>
		</div>

		<q-dialog v-model="delete_dialog">
			<q-card>
				<q-card-section class="row items-center">
					<span>Are you sure you want to delete the branding?</span>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn flat label="Delete" color="primary" @click="deleteBranding" />
				</q-card-actions>
			</q-card>
		</q-dialog>

		<q-dialog v-model="revert_dialog">
			<q-card>
				<q-card-section class="row items-center">
					<span
						>Are you sure you want to return to previous custom branding
						properties?</span
					>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn flat label="Revert" color="primary" @click="revertBranding" />
				</q-card-actions>
			</q-card>
		</q-dialog>

		<q-fab
			class="flex justify-end q-mt-md"
			style="position: sticky; bottom: 50px; z-index: 1"
			:label="$q.screen.gt.sm ? 'Actions' : ''"
			label-position="left"
			color="primary"
			icon="keyboard_arrow_up"
			direction="up"
		>
			<q-fab-action
				color="primary"
				icon="save"
				:label="$q.screen.gt.sm ? 'Save' : ''"
				@click="updateBranding"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Save
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				color="primary"
				icon="delete"
				:label="$q.screen.gt.sm ? 'Delete' : ''"
				@click="delete_dialog = true"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Delete
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				color="primary"
				icon="settings_backup_restore"
				:label="$q.screen.gt.sm ? 'Revert' : ''"
				@click="revert_dialog = true"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Revert
				</q-tooltip>
			</q-fab-action>
		</q-fab>
	</div>
</template>

<script>
import BrandingAPI from '@/services/api/Branding.js'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CustomBranding',
	meta: {
		title: `Alert Cascade - Custom Branding`
	},
	data() {
		return {
			delete_dialog: false,
			revert_dialog: false,
			is_ready: false,
			tabs: [
				{
					path_name: 'Custom Branding Logo',
					label: 'Logo',
				},
				{
					path_name: 'Custom Branding Colours',
					label: 'Colours',
				},
				{
					path_name: 'Custom Branding Contact',
					label: 'Contact',
				},
			],
			tabs_with_errors: [],
		}
	},
	computed: {
		...mapGetters([
			'current_CG',
			'branding_errors',
			'custom_branding',
			'brand_logo',
			'email_top_image',
			'email_bottom_image',
		]),
	},
	methods: {
		...mapActions(['getCustomBranding', 'updateBrandingErrors']),

		async updateBranding() {
			this.updateBrandingErrors('')
			this.tabs_with_errors = []
			const form = new FormData()
			if (this.brand_logo) {
				form.append('brand-logo', this.brand_logo)
			}
			if (this.email_top_image) {
				form.append('email-top-image', this.email_top_image)
			}
			if (this.email_bottom_image) {
				form.append('email-bottom-image', this.email_bottom_image)
			}
			form.append('branding-fields', JSON.stringify(this.custom_branding))
			await BrandingAPI.updateBranding(form)

			if (!this.branding_errors) {
				this.$q.notify('Custom branding saved successfully.')
				this.getPendingNotification()
			} else {
				for (let item in Object.keys(this.branding_errors)) {
					if (
						['brand_phone', 'brand_email', 'brand_login_url'].includes(
							Object.keys(this.branding_errors)[item]
						)
					) {
						this.tabs_with_errors.push('Custom Branding Contact')
					} else if (
						[
							'first_sentence',
							'reason_for_use',
							'export_header',
							'email_top_image',
							'email_bottom_image',
						].includes(Object.keys(this.branding_errors)[item])
					) {
						this.tabs_with_errors.push('Custom Branding Settings')
					} else if (
						['brand_logo'].includes(Object.keys(this.branding_errors)[item])
					) {
						this.tabs_with_errors.push('Custom Branding Logo')
					} else if (
						[
							'header_color',
							'header_icons_color',
							'highlight_color',
							'highlight_incident_color',
						].includes(Object.keys(this.branding_errors)[item])
					) {
						this.tabs_with_errors.push('Custom Branding Colours')
					}
				}
			}
		},

		async deleteBranding() {
			this.delete_dialog = false
			await BrandingAPI.deleteBranding(this.current_CG.customer_group.id)
			if (!this.branding_errors) {
				this.$q.notify('Custom branding is successfully deleted.')
			} else {
				this.$q.notify(this.branding_errors)
			}
		},
		async revertBranding() {
			this.revert_dialog = false
			await BrandingAPI.revertBranding()
			if (!this.branding_errors) {
				this.$q.notify('Successfully reverted to previous custom branding.')
			}
		},
		getPendingNotification() {
			if (!this.custom_branding.is_approved) {
				this.$q.notify({
					timeout: 6700,
					message:
						'Your custom branding is being processed, please be patient. Thank you.',
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		hasError(tab_name) {
			return this.tabs_with_errors.includes(tab_name)
		},
	},
	async created() {
		await this.getCustomBranding()
		this.is_ready = true
		if (
			this.current_CG.root_customer_group_permissions.includes(
				'Custom email branding'
			)
		) {
			this.tabs.push({
				path_name: 'Custom Branding Settings',
				label: 'Settings',
			})
		}
		this.getPendingNotification()
	},
}
</script>
<style lang="scss">
.settings-wrapper {
	text-align: left;
	border-radius: 0.375rem;
	padding: 1rem;
	margin-bottom: 1rem;
	width: 100%;
	background: $grey-1;
	border: 1px solid $grey-4;
}

.router-view-wrapper {
	padding: 1.5rem 3rem;
	background-color: #fff;
	@media (max-width: 768px) {
		padding: 1rem 0.5rem;
	}
}
</style>
